<template>
  <div class="w-40">
    <img
      src="/canopy-home-exteriors-logo.png"
      alt="Canopy Home Exterior Logo"
      class="h-auto w-auto max-w-full object-contain"
    >
  </div>
</template>

<style type="text/css" scoped>
.st0{fill:#282A2D;}
.st1{fill:#E64825;}
</style>
